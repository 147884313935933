.search-wrapper{
    width: 60%;
    margin: auto;
    min-width: 300px;
    height: 3.5rem;
    position: relative;
  }
  
  .search-box{
    width: 100%;
    position: absolute;
    background-color: var(--greyColor);
    box-shadow: rgba(2,11,20,0.2) 0px 5px 15px;
    top: -45%;
    height: 100%;
    width: 100%;
  }
  
  .search-card{
    display: flex;
    justify-content: space-between;
    height:100%;
    padding: 0.5rem;
  }
  
  .search-input{
   height: 100%;
   width:100%; 
   border: none;
   padding: 0.5rem;
  }
  
  .search-button{
    width:200px;
    background: var(--primaryColor);
    border:none;
    color: var(--whiteColor); 
    cursor: pointer;
  }