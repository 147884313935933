/* Job List */
.vitrine {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .vaga {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Garante alturas iguais */
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .vaga:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .vaga h2 {
    margin-bottom: 2.5rem;
    color: var(--primaryColor);
  }
  
  .vaga .descricao {
    flex-grow: 1; /* Faz o conteúdo expandir */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Garante espaço fixo entre descrição e local */
  }
  
  .vaga .local {
    margin-top: auto; /* Empurra o local para o final do bloco de descrição */
  }
  
  .vaga button {
    align-self: center; /* Alinha o botão no final */
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--primaryColor);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .vaga button:hover {
    background-color: #5db9e0;
  }
  