.featured { 
    width: 100%;
    height: auto;
    text-align: center;
    padding: 5rem 1rem ; 
    background-color: var(--greyColor);   
}

  .section-title{  
    text-align: center; 
    margin-bottom:0.6rem;
    letter-spacing: 1.5;
    font-size: 50px;
}

.featured-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap:2rem;
    align-items: center;
    width: 100%;
    padding: 2% 10%;
  }
  
  .featured-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: var(--whiteColor);
    box-shadow: rgba(0,0,0,0.16) 0px 1px 4px ;
  }
  
  .featured-card:hover{
    box-shadow:rgba(93, 93, 136, 0.2) 0px 7px 29px 0px ;
  }
  
  .featured-image {
    height: 40px;
    margin-bottom: 0.2rem;
  }
  
  .featured-button{
   border: 1px solid var(--primaryColor);
   background-color: transparent;   
   margin-top: 0.5rem;
   padding:3px 10px;
   cursor: pointer;
   color: var(--primaryColor); 
  }

  .featured-button:hover {
    background-color: var(--primaryColor);
    color: var(--whiteColor);
  }