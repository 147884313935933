.testimoni{
    width: 100%;
    text-align:justify;
    padding: 5rem 1rem;
    background-color: var(--primaryColor);
    color: var(--whiteColor);
  }

  .swiper {
    width: 90%;
    height: 500px;
    margin-top:1rem ;
  }
  
  .swiper-slide{
    background: var(--whiteColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    box-shadow: rgba(50, 50, 93, 0.25)rgba(0, 0, 0, 0.3)0px 8px 16px -8px;
    color: var(--blackColor);
  }
  
  .swiper-slide i{
    font-size: 50px;
    color: var(--labelColor);
    margin-bottom: 1rem;
  }
  
  .swiper-slide h3{
    font-family: 'Noto Serif', serif;
  }
  
  .testimoni p{
    font-size: 20px;
    text-align: center;
  }
  .testimoni-pic {
    padding: 1rem;
  }
  
  .testimoni-pic img {
    display: block;
    margin: 1rem auto;
    width: 80px;
    height: 50px;
    object-fit:cover;
    border-radius:50% ;
  }


  @media screen and (max-width:600px){ 
    .swiper {
      width: 94%;
      font-size: 20px;  
    }
  
    .swiper {
      width: 90%;
      height: 627px;
      margin-top: 1rem;
  }

  }