.navbar {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    padding: 1rem 2.5rem;
    z-index: 10;
    color: var(--whiteColor);
    background: var(--primaryColor);
  }
  
  .navbar-logo > a {
    letter-spacing: 2.7;
    text-decoration: none;
    color: var(--whiteColor);
  }
  
  .navbar-menu {
    width: 400px;
    display: flex;
    justify-content: space-between;
  }
  
  .navbar-menu > a {  
    color: var(--whiteColor);
    text-decoration: none;
  }
  
  .menu-toggle {
    display: none;
  }
  
  .menu-toggle .bar{
    width: 15px;
    height: 2px;
    margin:3px auto;
    transition: 3px auto;
    transition: all 0.3 ease-in-out;
    background-color: var(--whiteColor);
    cursor: pointer;
  }

  @media screen and (max-width:600px){
    .menu-toggle ,
  .bar{
      display: block;
      z-index: 3;
  }

  .menu-toggle.is-active .bar:nth-child(2){
      opacity: 0;
  }

  .menu-toggle.is-active .bar:nth-child(1){
      transform: translateY(5px) rotate(45deg);
  }

  .menu-toggle.is-active .bar:nth-child(3){
      transform: translateY(-5px) rotate(-45deg);
  }

  .navbar-menu {
      flex-direction: column;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 180px;
      width: 53%;
      height: 100vh;
      text-align: center;
      background-color: var(--blackColor);
      display: none;
      padding: 2rem;
  }

  .navbar-menu.active {
      display: flex;
  }

  .navbar-menu a {
      color:var(--whiteColor);
      padding: 1.5rem;
  }

  .header-title {
      font-size: 38px;
  }
}