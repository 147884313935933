header {
    width: 100%;
    height: 90vh; /* A altura será 800 pixels */
    background-image: url('/public/img/header-img.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .header-title{
    position: absolute;
    top: 7rem;
    left: 3rem;
    letter-spacing: bolder;
    font-size: 52px;
    color: var(--greyColor);
    text-shadow: 4px 2px 0px rgb(0,0,0,0,0.2);
  }
  
  .header-title > span {
    color:var(--primaryColor)
  }