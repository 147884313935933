/* Formulario Candidatos */
.job-application-form {
    background-color: var(--primaryColor);
    color: white;
    padding: 9px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(245, 219, 219, 0.2);
    max-width: 424px;
    margin:auto;
  }
  
  .job-application-form h2 {
    margin-bottom: 9px; /* Espaçamento abaixo do título */
    font-size: 1.4em; /* Tamanho do título */
  }
  
  .job-application-form button {
    width: 100%;
    height: 22px;
    background-color: var(--whiteColor);
    color: #282c34;
    padding: 0px 4px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form-group {
    margin-bottom: 10px; /* Espaçamento entre os campos do formulário */
  }
  
  .form-group label {
    display: block; /* Para garantir que o label fique em uma linha separada */
    margin-bottom: 4px; /* Espaçamento abaixo do label */
    color:var(--whiteColor); /* Cor dos labels */
  }
  
  .form-group input,
  .form-group textarea, .form-group select {
    width: 100%; /* Largura total do campo */
    padding: 5px; /* Espaçamento interno */
    border: 1px solid var(--yellowColor); /* Borda colorida */
    border-radius: 4px; /* Bordas arredondadas */
    background-color: #fff; /* Fundo dos campos */
    color: #000; /* Cor do texto dos campos */
  }
  
  
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none; /* Remove o contorno padrão */
    border-color: #61dafb; /* Cor da borda quando em foco */
  }