.blog {
    width: 100%;
    height: auto;
    text-align: center;
    padding: 5rem 1rem;
  }
  
  .blog-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 1ren;
  }
  
  .blog-card{
    width: 300px;
    height: auto;
    margin: 1rem;
    box-shadow: 0 1px 6px 0 rgba(49, 53, 59 / 12%);
    cursor: pointer;
  }
  
  .blog-image{
  width: 100%;
  height: 150px;
  object-fit:cover;  
  }
  
  .blog-detail{
    padding: 28px;
  }
  
  .blog-detail span {
    color: var(--yellowColor);
    font-size: 10px 0;
  }
  
  .blog-detail h4{
    padding: 10px 0;
  }
  
  .divider{
    margin: 1rem 0;
    border: 0.5px solid var(--greyColor);
  }
  
  .blog-more{
    color: var(--primaryColor);
     text-decoration: none;
     font-size:0.9rem ;
  
  }
  
  .blog-card:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0 ;
  }