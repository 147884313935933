:root {
  --primaryColor:#15ab92;
  --whiteColor:#fff;
  --blackColor:#333;
  --greyColor:#f6f8fa;
  --labelColor:#15ab92;
  --yellowColor:#ffd723;    
}

* {
margin:0;
padding:0;
box-sizing: border-box;
outline: none;
font-family: 'Nunito',sans-serif;
}
/* Oculta a barra de rolagem para o elemento body e html */
html, body {
  height: 100%;
  margin: 0;  
}

.wrapper {
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Oculta a barra no Internet Explorer */
  scrollbar-width: none; /* Oculta a barra no Firefox */
}

.wrapper::-webkit-scrollbar {
  display: none; /* Oculta a barra no Chrome, Safari e Opera */
}

h1{
  line-height: 1.5;
}

.filter-box {    
  width: 100%;
  height: auto;
  font-size: 0.8rem;
  width: 60%;
  min-width: 300px;
  margin: auto;
}

.filter-dropdown{   
  width: 100%;
  border-bottom: 1px solid var(--primaryColor);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.3rem;
}

.filter-select {      
  border: none;
  padding: 0.2rem;
}

.filter-select:hover {   
  background:var(--greyColor);
}

.filter-chosen {
  width: 100%;
  padding:0.3rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.chosen-card{
  background: var(--primaryColor);
  padding: 0.2rem 0.5rem;
  border-radius: 20px; 
  color: var(--whiteColor);
  cursor: pointer;
}

button:hover {
  background-color: #5db9e0; /* Cor do botão ao passar o mouse */
}


/* Formulario */
.inline-fields {
  display: flex;
  justify-content: space-between; /* Para espaçamento uniforme entre os campos */
  gap: 10px; /* Espaçamento entre os campos */
}

.form-group {
  flex: 1; /* Cada campo ocupa uma parte igual do espaço disponível */
}

/* Opcional: ajuste a largura máxima para os campos */
.inline-fields .form-group {
  max-width: 48%; /* Limita a largura máxima dos campos */
}





/* Pop-up */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.job-application-form {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.job-element {
  position: absolute;
  inset: 40px;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  width: 430px;
  height: 550px;
  margin: 20px auto;
}


/* Mobile */


@media screen and (max-width:600px){ 

  .job-card {
    display: flex;
    padding: 1rem;
    min-height: 100px;
    background-color: var(--whiteColor);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
  }

  .job-label {
      display: none;
  }

  .job-register{ 
    margin: 10px 0 auto;
  }

  .job-detail {
    font-size: 20px;
    width: 20rem;   
    padding: 0 10px;
    margin: 10px -9rem auto;
  }

  .job-profile{
    margin: 25px -80px auto;
  }

  .job-button{
    display: block;
    padding:0.3rem 0.1rem;
    margin: 20px auto;
    width: 90px;
    background-color:var(--primaryColor);
    color: var(--whiteColor);
    border: 1px solid var(--primaryColor);
    border-radius: 20px;
    cursor: pointer;
  }

  .join-detail {
    width: 100%;   
}

  .join-detail h1{
    font-size: 20px;
  }
  .join-detail p{
    margin: 0 1px auto;
    font-size: 12px;
  } 



.job-element {
  position: absolute;
  inset: 40px;
  border-radius: 10px;
  outline: none;
  padding: 8px;
  width: 300px;
  height: 550px;
  margin: 20px auto;
}

}


@media screen and (max-width:480px) {
  .job-posted{
      display: none;
  }
}


